export default {
  data() {
    return {
      regionList: [
        {
          txt: '설악',
          val: 'Sulak',
        },
        {
          txt: '가평',
          val: 'Gapyeong',
        },
        {
          txt: '영등포',
          val: 'Yeongdeungpo',
        },
        {
          txt: '강남',
          val: 'KangNam',
        },
        {
          txt: '소공동',
          val: 'sogong',
        },
        {
          txt: '판교',
          val: 'Pangyo',
        },
        {
          txt: '광교',
          val: 'Gwanggyo',
          mod: 'regionGwanggyo',
        },
      ],
      positionList: [
        {
          txt: '객실관리',
          val: '1',
        },
        {
          txt: '후론트',
          val: '3',
        },
        {
          txt: '식음(F&B)',
          val: '5',
        },
        {
          txt: '주방',
          val: '12',
        },
        {
          txt: '시설 보안',
          val: '13',
        },
        {
          txt: '데이터 사이언스',
          val: '35',
        },
      ],
      wageList: [
        {
          txt: '시급',
          val: 'hour',
        },
        {
          txt: '일급',
          val: 'day',
        },
        {
          txt: '월급',
          val: 'month',
        },
      ],
      emploList: [
        {
          txt: '단기알바',
          val: 'short',
        },
        {
          txt: '장기알바',
          val: 'long',
        },
        {
          txt: '정규직',
          val: 'perm',
        },
      ],
      occupationList: [
        {
          Val: 'FnB',
          Nm: '식음료',
          Text: '식음료',
          Key: 'Kal',
        },
        {
          Val: 'FnB',
          Nm: '식음료',
          Text: '식음료',
          Key: 'Hi',
        },
        {
          Val: 'reception',
          Nm: '리셉션',
          Text: '리셉션',
          Key: 'Hi',
        },
        {
          Val: 'concierge',
          Nm: '컨시어즈',
          Text: '컨시어즈',
          Key: 'Hi',
        },
      ],
    }
  },
  watch: {
    motive(newVal) {
      this.wordCount = this.byteLength(newVal)
    },
    eventId() {
      if (this.eventId == 0) {
        this.isApplicationForm = false
      } else {
        this.isApplicationForm = true
      }
    },
    wage() {
      if (this.wage === 'hour') {
        this.maxFix = 150000
        this.step = 5000
      }
      if (this.wage === 'day') {
        this.maxFix = 300000
        this.step = 10000
      }
      if (this.wage === 'month') {
        this.maxFix = 5000000
        this.step = 50000
      }
      this.medianVal = this.maxFix + (this.minFix - this.maxFix) / 2
      this.$refs.dualRange.sBarMaxValue = this.medianVal
    },
  },
  methods: {
    sBarValues(min, max) {
      this.minVal = min
      this.maxVal = max
      console.log('parent', this.maxVal)
    },
    toggleOpen() {
      this.isOpenShow = !this.isOpenShow
    },
    byteLength(str) {
      let count = 0
      let ch = ''
      for (let i = 0; i < str.length; i++) {
        ch = str.charAt(i)
        if (escape(ch).length === 6) {
          count++
        }
        count++
      }
      return count
    },
    validName(data) {
      return /[ㄱ-ㅎ|ㅏ-ㅣ|가-힣]/g.test(data)
    },
    validNumber(data) {
      return /[0-9]/g.test(data)
    },
    validEmail(data) {
      return /[-\d\S.+_]+@[-\d\S.+_]+\.[\S]{2,4}/.test(data)
    },
    validPhone(data) {
      return /^01([0|1|6|7|8|9])-?([0-9]{3,4})-?([0-9]{4})$/.test(data)
    },
    validSpecial(data) {
      return /([\u2700-\u27BF]|[\uE000-\uF8FF]|\uD83C[\uDC00-\uDFFF]|\uD83D[\uDC00-\uDFFF]|[\u2011-\u26FF]|\uD83E[\uDD10-\uDDFF])/g.test(
        data,
      )
    },
  },
  computed: {},
}
